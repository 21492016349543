import { AdditionalPageInfo } from '@/redux/reducers/additionalPages';
import { MAX_VISIBLE_NAV_LINKS } from '@/utils/constants';
import getNavList from '@/utils/getNavList';

export const getNavigationListFiltered = (
  navigationList: AdditionalPageInfo[],
  visibleItemsLength = MAX_VISIBLE_NAV_LINKS,
) => {
  return getNavList(navigationList, visibleItemsLength);
};
