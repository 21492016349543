import { useSelector } from '@/redux/reducers';
import { useSSRSelector } from '@/redux/ssrStore';
import { getNavigationListFiltered } from './headerNavigationUtils';

export const useHeaderData = (headerProps) => {
  const { storeData, navigationList, collections } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
    navigationList: state.additionalPagesReducer.navigationList,
    collections: state.catalogReducer.collections,
  }));

  const navItems = getNavigationListFiltered(navigationList);

  const { items, user } = useSelector((state) => ({
    items: state.cartReducer.items,
    user: state.userReducer,
  }));

  const headerBgColor = headerProps?.config?.color_scheme?.bg_color || '#FFFFFF';

  return {
    storeData,
    items,
    collections,
    user,
    navItems,
    headerBgColor,
  };
};
