export const ShoppingBag = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '16'}
      height={props.height || '18.963'}
      viewBox="0 0 16 18.963"
    >
      <g id="shopping-bag-6" transform="translate(-40)">
        <path
          id="Path_132897"
          data-name="Path 132897"
          d="M55.259,4.444H53a5.037,5.037,0,0,0-10,0H40.741A.741.741,0,0,0,40,5.185V18.222a.741.741,0,0,0,.741.741H55.259A.741.741,0,0,0,56,18.222V5.185A.741.741,0,0,0,55.259,4.444ZM48,1.481a3.561,3.561,0,0,1,3.506,2.963H44.494A3.561,3.561,0,0,1,48,1.481Zm6.519,16H41.481V5.926H54.519Z"
          fill={props.color}
        />
      </g>
    </svg>
  );
};
